<template>
  <div class="manage">
    <div class="title">{{ $t("adnavPage.supplierManagement") }}</div>
    <div class="flex-base">
      <div class="flex-base search-condition">
        <p class="condition-title">{{ $t("systemPage.supplierNameColumn") }}</p>
        <el-input class="condition-input" v-model="companyName"></el-input>
      </div>
      <div>
        <el-button class="btn-black" size="small" @click="searchData">{{ $t("search") }}</el-button>
      </div>
    </div>
    <el-table header-row-class-name="word" :data="tableData">
      <template slot="empty">
        <div v-loading="tableLoading" v-text="$t('tableEmpty')"></div>
      </template>
      <el-table-column
        prop="username"
        :label="$t('systemPage.supplierName')"
        align="center"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="address"
        :label="$t('systemPage.address')"
        align="center"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="createdAt"
        :label="$t('systemPage.creationTime')"
        align="center"
        width="200"
      >
        <template v-slot="scope">{{ scope.row.createdAt | timeStampToDate }}</template>
      </el-table-column>
      <el-table-column prop="approved" :label="$t('systemPage.status')" align="center" width="100">
        <template v-slot="scope">{{ scope.row.approved | authStatus }}</template>
      </el-table-column>
      <el-table-column
        prop="financingCount"
        :label="$t('systemPage.numberOfFinancingOrder')"
        align="center"
        width="200"
      ></el-table-column>
      <el-table-column
          prop="auditLog.adminUsername"
          :label="$t('tableI18n.Reviewed')"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="auditLog.createdAt"
          :label="$t('tableI18n.ReviewedTime')"
          align="center"
          width="200"
      >
        <template v-slot="scope">{{ scope.row.auditLog && scope.row.auditLog.createdAt }}</template>
      </el-table-column>
      <el-table-column prop="operation" :label="$t('operation')" align="center" width="100">
        <template v-slot="scope">
          <el-button
            v-if="scope.row.approved && scope.row.approved.toString() === authAll.PROCESSING.valueNew"
            class="btn-black"
            size="small"
            @click="toDetail(scope.row)"
          >{{ $t("systemPage.approve") }}</el-button>
          <!-- <el-button
            v-else
            class="btn-black"
            size="small"
            :disabled="(scope.row.approved && scope.row.approved.toString() === authAll.UNKNOWN.valueNew) || (scope.row.approved && scope.row.approved.toString() === authAll.UNKNOWNTOO.valueNew)"
            @click="toDetail(scope.row)"
          >{{ $t("see") }}</el-button>-->
          <el-button
            v-else
            class="btn-black"
            size="small"
            :disabled="scope.row.approved.toString() === authAll.UNKNOWN.valueNew || scope.row.approved.toString() === authAll.UNKNOWNTOO.valueNew"
            @click="toDetail(scope.row)"
          >{{ $t("see") }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <AdPagination
      :currentPage="tablePageCP"
      :pageSize="tablePagePS"
      :pageTotal="tableTotal"
      @handlePage="handlePage"
    ></AdPagination>
  </div>
</template>

<script>
import { connect, getFinanceRequests } from '../../utils/blockchain';
import AdPagination from "@/components/ad-pagination";

export default {
  name: "supplier-management",
  components: {
    AdPagination
  },
  data() {
    return {
      tableData: [],
      tableLoading: false,
      companyName: "",
      authAll: this.$enums.AUTH,
      maps: new Map(),
      tableTotal: 0,
      tablePageCP: 1,
      tablePagePS: 10,
    };
  },
  created() {
    this.initData();
  },
  methods: {
    handlePage(val) {
      this.tablePageCP = val.currentPage;
      this.tablePagePS = val.pageSize;
      this.initData();
    },
    initData() {
      this.tableLoading = true;
      let _this = this;
      let params = {
        name: this.companyName,
        page: this.tablePageCP,
        pageSize: this.tablePagePS
      };
      this.$axios.get("/manage-supplier/users", { params: params }).then(result => {
        _this.tableLoading = false;
        if (!result.code) {
          _this.tableData = result.data.rows;
          _this.tableTotal = result.data.count || 0;
        }
      }).catch(error => {
        _this.tableLoading = false;
        console.log(error);
      });
    },
    searchData() {
      this.initData();
    },
    toDetail(row) {
      this.$router.push({ path: "/supplier-detail", query: { id: row.id, auth: row.auth } });
    }
  },
};
</script>

<style scoped lang="scss">
.manage {
  .search-condition {
    .condition-title {
      min-width: 113px;
      max-width: 169px;
      margin-right: 10px;
    }
    .condition-input {
      width: 346px;
      height: 35px;
      margin-right: 20px;
    }
  }
  .flex-between {
    margin-bottom: 16px;
  }
}
</style>
